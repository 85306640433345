<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCardHeader>
          <strong>
            <h5>Homepage</h5>
          </strong>
        </CCardHeader>
        <br />
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="12">
        <CCardHeader>
          <strong>Logo Azienda</strong>
        </CCardHeader>
        <CCard>
          <CCardBody>
            <UploadSinglePhotoAndPreview
              @getPhoto="
                (data) => {
                  this.logo_azienda = data;
                }
              "
              :img="logo_azienda"
              title="Carica qui il logo dell'azienda"
              subtitle="Logo Azienda"
            />
            <br />
          </CCardBody>
          <br />
        </CCard>
      </CCol>
    </CRow>

    <!-- SECTION CHOOSE TEMPLATE -->
    <CRow>
      <CCol sm="12">
        <CCardHeader>
          <strong>Template</strong>
        </CCardHeader>
        <CCard class="customCard">
          <CJumbotron>
            <div class="listTotem" v-for="item in templates" :key="item.id">
              <img
                :id="item.id"
                height="200"
                width="auto"
                :class="{
                  template: 'template',
                  isActive: item.isActive,
                }"
                v-on:click="chooseTemplate(item)"
                :src="item.src"
                alt=""
              />
            </div>
          </CJumbotron>
        </CCard>
      </CCol>
    </CRow>
    <!-- SECTION END CHOOSE TEMPLATE -->

    <!-- Impostazione colori totem -->
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Colori</strong>
          </CCardHeader>

          <CCardBody>
            <table class="table">
              <thead>
                <tr>
                  <th>Sfondo 1</th>
                  <th>Sfondo 2</th>
                  <th>Sfondo 3</th>
                  <th>Testo sfondo 1</th>
                  <th>Testo sfondo 2</th>
                  <th>Testo sfondo 3</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <v-swatches
                      v-model="sfondo_1"
                      popover-x="right"
                      show-fallback
                      fallback-input-type="color"
                    ></v-swatches>
                  </td>

                  <td>
                    <v-swatches
                      v-model="sfondo_2"
                      popover-x="right"
                      show-fallback
                      fallback-input-type="color"
                    ></v-swatches>
                  </td>

                  <td>
                    <v-swatches
                      v-model="sfondo_3"
                      popover-x="right"
                      show-fallback
                      fallback-input-type="color"
                    ></v-swatches>
                  </td>

                  <td>
                    <v-swatches
                      v-model="testo_sfondo_1"
                      popover-x="right"
                      show-fallback
                      fallback-input-type="color"
                    ></v-swatches>
                  </td>

                  <td>
                    <v-swatches
                      v-model="testo_sfondo_2"
                      popover-x="right"
                      show-fallback
                      fallback-input-type="color"
                    ></v-swatches>
                  </td>

                  <td>
                    <v-swatches
                      v-model="testo_sfondo_3"
                      popover-x="right"
                      show-fallback
                      fallback-input-type="color"
                    ></v-swatches>
                  </td>
                </tr>
              </tbody>
            </table>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!-- Fine impostazione colori totem -->

    <!-- SECTION CHOOSE TEXTURE -->
    <CRow>
      <CCol sm="12">
        <CCardHeader>
          <strong>Texture</strong>
        </CCardHeader>
        <CCard class="customCard">
          <CJumbotron>
            <div class="listTotem" v-for="item in textures" :key="item.id">
              <img
                :id="item.id"
                height="200"
                width="auto"
                :class="{
                  texture: 'texture',
                  isActive: item.isActive,
                }"
                v-on:click="chooseTexture(item)"
                :src="item.src"
                alt=""
              />
            </div>
          </CJumbotron>
        </CCard>
      </CCol>
    </CRow>
    <!-- SECTION END CHOOSE TEMPLATE -->

    <!-- UPLOAD PHOTO -->
    <div>
      <UploadMultiplePhotoAndPreview
        @getPhoto="
          (data) => {
            this.foto_homepage = data;
          }
        "
        :img="foto_homepage"
        :aspectRatioVariableProps="aspectRatioVariableProps"
        title="Homepage"
        subtitle="Logo Azienda"
      />
    </div>
    <!-- END UPLOAD PHOTO -->

    <div class="custom-button-manifesto-extra-space">
      <CButton v-on:click="save()" type="submit" color="info">
        <CIcon name="cil-save" />
        Salva
      </CButton>
    </div>
    <br />
  </div>
</template>

<script>
import { Agenzia } from '@/services/api/agenzia';
import UploadMultiplePhotoAndPreview from '@/views/funer24/components/UploadMultiplePhotoAndPreview';
import UploadSinglePhotoAndPreview from '@/views/funer24/components/UploadSinglePhotoAndPreview';
import VSwatches from 'vue-swatches';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Homepage',
  components: {
    UploadMultiplePhotoAndPreview,
    UploadSinglePhotoAndPreview,
    VSwatches,
  },
  data: function () {
    return {
      foto_homepage: null,
      logo_azienda: null,
      templates: [],
      textures: [],
      template: null,
      texture: null,
      sfondo_1: null,
      sfondo_2: null,
      sfondo_3: null,
      testo_sfondo_1: null,
      testo_sfondo_2: null,
      testo_sfondo_3: null,
      aspectRatioVariableProps: 1.78,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('agenzia', ['agenzia']),
  },
  methods: {
    ...mapActions('agenzia', ['update']),

    chooseTemplate(item) {
      this.templates.forEach((value) => {
        value.isActive = value.id === item.id;
      });
      this.template = item;
    },

    chooseTexture(item) {
      this.textures.forEach((value) => {
        value.isActive = value.id === item.id;
      });
      this.texture = item;
    },

    async getData() {
      this.isLoading = true;
      try {
        // Parallelizzazione delle richieste API
        const [templatesResponse, texturesResponse, agenziaResponse] = await Promise.all([
          Agenzia.templates(),
          Agenzia.texture(),
          Agenzia.get(),
        ]);

        // Estrazione dei risultati dalle risposte
        const templates = templatesResponse.data.results;
        const textures = texturesResponse.data.results;
        const agenzia = agenziaResponse.data.results[0];

        // Mappatura dei templates
        this.templates = templates.map((value) => ({
          id: value.id,
          src: value.img,
          isActive: false,
        }));

        // Mappatura delle textures
        this.textures = textures.map((value) => ({
          id: value.id,
          src: value.img,
          isActive: false,
        }));

        // Impostazione dei dati dell'agenzia
        this.setAgenziaData(agenzia);
      } catch (error) {
        console.error('Errore durante il recupero dei dati:', error);
        // Qui potresti aggiungere una gestione più sofisticata degli errori
        // ad esempio mostrando un messaggio all'utente
      } finally {
        this.isLoading = false;
      }
    },

    /*************  ✨ Codeium Command ⭐  *************/
    /**
     * Imposta i campi dell'agenzia per la homepage
     * @param {Object} agenzia - Oggetto con i dati dell'agenzia
     */
    /******  4e6ba1ae-127e-4aa0-8223-e20a777ab230  *******/ setAgenziaData(agenzia) {
      // Impostazione dei campi sfondo e testo
      this.sfondo_1 = agenzia.sfondo_1;
      this.sfondo_2 = agenzia.sfondo_2;
      this.sfondo_3 = agenzia.sfondo_3;
      this.testo_sfondo_1 = agenzia.testo_sfondo_1;
      this.testo_sfondo_2 = agenzia.testo_sfondo_2;
      this.testo_sfondo_3 = agenzia.testo_sfondo_3;

      // Impostazione del template se presente
      if (agenzia.template !== null) {
        this.chooseTemplate(agenzia.template);
      }

      // Impostazione della texture se presente
      if (agenzia.texture !== null) {
        this.chooseTexture(agenzia.texture);
      }

      // Impostazione del logo e foto homepage se presenti
      this.logo_azienda = agenzia.logo_azienda || null;
      this.foto_homepage = agenzia.foto_homepage || null;
    },

    async save() {
      this.isLoading = true;
      try {
        const result = await this.update({
          template: this.template?.id || null,
          texture: this.texture?.id || null,
          sfondo_1: this.sfondo_1,
          sfondo_2: this.sfondo_2,
          sfondo_3: this.sfondo_3,
          testo_sfondo_1: this.testo_sfondo_1,
          testo_sfondo_2: this.testo_sfondo_2,
          testo_sfondo_3: this.testo_sfondo_3,
          logo_azienda: this.logo_azienda,
          foto_homepage: this.foto_homepage,
        });

        if (result) {
          this.$router.push('/dashboard');
        }
      } catch (error) {
        console.error('Errore durante il salvataggio:', error);
        // Qui potresti aggiungere una notifica per l'utente
      } finally {
        this.isLoading = false;
      }
    },
  },

  async mounted() {
    await this.getData();
  },
};
</script>

<style scoped>
tbody {
  background-color: #e3e3e3;
  margin-top: 15px;
  margin-bottom: 8px;
  padding-top: 17px !important;
}
.preview_manifesto {
  width: 250px;
  margin: 0 auto;
  padding: 0 !important;
}
.template {
  opacity: 0.3;
}
.template.isActive {
  opacity: 1;
}
.texture {
  opacity: 0.3;
}
.texture.isActive {
  opacity: 1;
}
img.preview_manifesto.d-block {
  width: 250px !important;
}
</style>
